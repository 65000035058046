import { bindable, inject }              from 'aurelia-framework';
import { AppContainer }                  from 'resources/services/app-container';
import { BaseFormViewModel }             from 'base-form-view-model';
import { FormSchema }                    from 'modules/entities/managing-entities/form-schema';
import { JasminSettingsRepository }      from 'modules/administration/jasmin-settings/services/repository';
import { ManagingEntitiesRepository }    from 'modules/entities/managing-entities/services/repository';
import { ManagingEntityFilesRepository } from 'modules/entities/managing-entities/services/files-repository';

@inject(AppContainer, FormSchema, ManagingEntitiesRepository, ManagingEntityFilesRepository, JasminSettingsRepository)
export class EditManagingEntity extends BaseFormViewModel {

    @bindable headerTitle    = 'form.title.edit-record';
    @bindable newRecordRoute = 'entities.managing-entities.create';
    @bindable formId         = 'entities-managing-entities-edition-form';

    alert  = {};
    model  = {};
    schema = {};

    /**
     * Constructor
     *
     * @param appContainer
     * @param formSchema
     * @param repository
     * @param filesRepository
     * @param jasminSettingsRepository
     */
    constructor(appContainer, formSchema, repository, filesRepository, jasminSettingsRepository) {
        super(appContainer);

        this.formSchema               = formSchema;
        this.repository               = repository;
        this.filesRepository          = filesRepository;
        this.jasminSettingsRepository = jasminSettingsRepository;
        this.newRecordRoute           = appContainer.authenticatedUser.can(['entities.managing-entities.manage', 'entities.managing-entities.create']) ? this.newRecordRoute : null;
    }

    /**
     * Returns view strategy
     *
     * @returns {string}
     */
    getViewStrategy() {
        return PLATFORM.moduleName('modules/entities/managing-entities/form.html');
    }

    /**
     * Handles canActivate event
     */
    canActivate(params) {
        const authenticatedUser = this.appContainer.authenticatedUser;
        const belongsToEntity   = authenticatedUser.can('entities.managing-entities.edit') && authenticatedUser.belongsToEntity(params.id);

        return super.authorize(authenticatedUser.can('entities.managing-entities.manage') || belongsToEntity);
    }

    /**
     * Handles activate event
     */
    activate(params, navigationInstruction) {
        super.activate(navigationInstruction);

        this.initialModel = this.formSchema.model();
        this.model        = this.formSchema.model();

        return this.fetchData(params).then(() => {
            this.schema                = this.formSchema.schema(this);
            this.easypaySettingsSchema = this.formSchema.easypaySettingsSchema(this);
            this.jasminSettingsSchema  = this.formSchema.jasminSettingsSchema(this);
            this.smsSettingsSchema     = this.formSchema.smsSettingsSchema(this);
            this.emailSettingsSchema   = this.formSchema.emailSettingsSchema(this);
            this.responsiblesSchema    = this.formSchema.responsiblesSchema(this);
            this.buttonsSchema         = this.formSchema.buttonsSchema(this);
        });
    }

    /**
     * Submits view form
     */
    submit() {
        this.alert = null;

        return this.repository
            .update(this.model.id, this.model)
            .then(response => this.afterSubmitCallback(response))
            .then(response => this.afterResponseCallback(response));
    }

    /**
     * Tests the Jasmin settings
     */
    testJasminSettings() {
        this.alert = null;

        return this.jasminSettingsRepository.test(this.model).then(response => this.afterSubmitCallback(response));
    }

    /**
     * Handles the submit form response
     *
     * @param response
     */
    afterSubmitCallback(response) {
        if (response.status === true) {
            this.appContainer.notifier.successNotice(response.message);

            this.initialModel.assign(this.model);
        } else {
            this.alert = this.alertMessage(response.status, response.message, response.errors);
        }

        return response;
    }

    /**
     * Handles the received response
     *
     * @param response
     */
    afterResponseCallback(response) {
        if (response.status === true && ! this.appContainer.authenticatedUser.belongsToManagingEntity(false)) {
            this.appContainer.router.navigateToRoute('entities.managing-entities.index');
        }
    }

    /**
     * Handles the test response
     *
     * @param response
     */
    afterTestCallback(response) {
        if (response.status === true) {
            this.appContainer.notifier.successNotice(response.message);
        } else {
            this.alert = this.alertMessage(response.status, response.message, response.errors);
        }

        return response;
    }

    /**
     * Fetches data from server
     *
     * @param {id}
     *
     * @returns {Promise.<*>|Promise}
     */
    fetchData({ id }) {
        return this.repository.find(id).then((response) => {
            this.initialModel.assign(response);
            this.model.assign(response);
        });
    }

}
