import { inject }                                  from 'aurelia-framework';
import { BooleanStatus }                           from 'modules/administration/models/boolean-status';
import { CountiesRepository }                      from 'modules/administration/locations/counties/services/repository';
import { ManagingEntitiesRepository }              from 'modules/entities/managing-entities/services/repository';
import { ManagingEntity }                          from 'modules/entities/models/managing-entity';
import { ParishesRepository }                      from 'modules/administration/locations/parishes/services/repository';
import { SingleFormSchema as LocationsFormSchema } from 'modules/administration/locations/reusable/single-form-schema';

@inject(CountiesRepository, LocationsFormSchema, ManagingEntitiesRepository, ParishesRepository)
export class FormSchema {

    /**
     * Model default values
     *
     * @type {{}}
     */
    modelDefaults = {
        status_id: BooleanStatus.ACTIVE,
    };

    /**
     * Constructor
     *
     * @param countiesRepository
     * @param locationsFormSchema
     * @param managingEntitiesRepository
     * @param parishesRepository
     */
    constructor(countiesRepository, locationsFormSchema, managingEntitiesRepository, parishesRepository) {
        this.countiesRepository         = countiesRepository;
        this.locationsFormSchema        = locationsFormSchema;
        this.managingEntitiesRepository = managingEntitiesRepository;
        this.parishesRepository         = parishesRepository;
    }

    /**
     * Returns a new instance of the model
     *
     * @returns {ManagingEntity}
     */
    model() {
        let model = new ManagingEntity();

        model.assign(this.modelDefaults);

        return model;
    }

    /**
     * Returns the data form schema
     *
     * @param viewModel
     *
     * @returns {*[]}
     */
    schema(viewModel) {
        this.name = {
            type:  'text',
            key:   'name',
            label: 'form.field.designation',
            size:  12,
        };

        this.address = {
            type:     'text',
            key:      'address',
            label:    'form.field.address',
            size:     12,
            required: false,
        };

        this.zip_code = {
            type:     'text',
            key:      'zip_code',
            label:    'form.field.zip-code',
            size:     4,
            required: false,
        };

        this.locality = {
            type:     'text',
            key:      'locality',
            label:    'form.field.locality',
            size:     4,
            required: false,
        };

        this.phone_number = {
            type:     'text',
            key:      'phone_number',
            label:    'form.field.phone-number',
            size:     4,
            required: false,
        };

        this.mobile_number = {
            type:     'text',
            key:      'mobile_number',
            label:    'form.field.mobile-number',
            size:     4,
            required: false,
        };

        this.fax_number = {
            type:     'text',
            key:      'fax_number',
            label:    'form.field.fax-number',
            size:     4,
            required: false,
        };

        this.email = {
            type:     'email',
            key:      'email',
            label:    'form.field.email',
            size:     4,
            required: false,
        };

        this.tax_identification_number = {
            type:     'text',
            key:      'tax_identification_number',
            label:    'form.field.tax-number',
            size:     4,
            required: false,
        };

        this.status_id = {
            type:  'boolean-statuses-select',
            key:   'status_id',
            label: 'form.field.status',
            size:  4,
        };

        return [
            [this.name],
            ...this.locationsFormSchema.schema(viewModel.model, true),
            [this.address],
            [this.zip_code, this.locality],
            [this.phone_number, this.mobile_number, this.fax_number],
            [this.email, this.tax_identification_number, this.status_id],
        ];
    }

    /**
     * Returns the Easypay settings form schema
     *
     * @param viewModel
     *
     * @returns {*[]}
     */
    easypaySettingsSchema(viewModel) {
        this.account_id = {
            type:  'text',
            key:   'account_id',
            label: 'form.field.account-id',
            size:  4,
        };

        this.api_key = {
            type:       'password',
            key:        'api_key',
            label:      'form.field.api-key',
            size:       4,
            inputGroup: {
                position: 'right',
                button:   {
                    title:   'form.button.show/hide',
                    action:  () => {
                        this.api_key.type                         = this.api_key.type === 'password' ? 'text' : 'password';
                        this.api_key.inputGroup.button.icon.class = this.api_key.type === 'password' ? 'icon-eye' : 'icon-eye-blocked';
                    },
                    icon:    { class: 'icon-eye' },
                    visible: true,
                },
            },
        };

        return [
            [this.account_id, this.api_key],
        ];
    }

    /**
     * Returns the Jasmin settings form schema
     *
     * @param viewModel
     *
     * @returns {*[]}
     */
    jasminSettingsSchema(viewModel) {
        this.client_id = {
            type:     'text',
            key:      'client_id',
            label:    'form.field.client-id',
            size:     3,
            required: false,
        };

        this.client_secret = {
            type:       'password',
            key:        'client_secret',
            label:      'form.field.client-secret',
            size:       3,
            required:   false,
            inputGroup: {
                position: 'right',
                button:   {
                    title:   'form.button.show/hide',
                    action:  () => {
                        this.client_secret.type                         = this.client_secret.type === 'password' ? 'text' : 'password';
                        this.client_secret.inputGroup.button.icon.class = this.client_secret.type === 'password' ? 'icon-eye' : 'icon-eye-blocked';
                    },
                    icon:    { class: 'icon-eye' },
                    visible: true,
                },
            },
        };

        this.api_tenant = {
            type:     'text',
            key:      'api_tenant',
            label:    'form.field.tenant-key',
            size:     3,
            required: false,
        };

        this.api_organization = {
            type:     'text',
            key:      'api_organization',
            label:    'form.field.organization-key',
            size:     3,
            required: false,
        };

        this.periodic_inspection_item_code = {
            type:     'text',
            key:      'periodic_inspection_item_code',
            label:    'form.field.periodic-inspection-item-code',
            size:     3,
            required: false,
        };

        this.extraordinary_inspection_item_code = {
            type:     'text',
            key:      'extraordinary_inspection_item_code',
            label:    'form.field.extraordinary-inspection-item-code',
            size:     3,
            required: false,
        };

        this.reinspection_item_code = {
            type:     'text',
            key:      'reinspection_item_code',
            label:    'form.field.reinspection-item-code',
            size:     3,
            required: false,
        };

        this.sealing_item_code = {
            type:     'text',
            key:      'sealing_item_code',
            label:    'form.field.sealing-item-code',
            size:     3,
            required: false,
        };

        this.financial_account_code = {
            type:     'text',
            key:      'financial_account_code',
            label:    'form.field.financial-account-code',
            size:     3,
            required: false,
        };

        return [
            [this.client_id, this.client_secret, this.api_tenant, this.api_organization],
            [this.periodic_inspection_item_code, this.extraordinary_inspection_item_code, this.reinspection_item_code, this.sealing_item_code],
            [this.financial_account_code],
        ];
    }

    /**
     * Returns the SMS settings form schema
     *
     * @param viewModel
     *
     * @returns {*[]}
     */
    smsSettingsSchema(viewModel) {
        this.msisdn = {
            type:     'text',
            key:      'msisdn',
            label:    'form.field.msisdn',
            size:     4,
            required: false,
        };

        this.password = {
            type:       'password',
            key:        'password',
            label:      'form.field.password',
            size:       4,
            required:   false,
            inputGroup: {
                position: 'right',
                button:   {
                    title:   'form.button.show/hide',
                    action:  () => {
                        this.password.type                         = this.password.type === 'password' ? 'text' : 'password';
                        this.password.inputGroup.button.icon.class = this.password.type === 'password' ? 'icon-eye' : 'icon-eye-blocked';
                    },
                    icon:    { class: 'icon-eye' },
                    visible: true,
                },
            },
        };

        return [
            [this.msisdn, this.password],
        ];
    }

    /**
     * Returns the email settings form schema
     *
     * @param viewModel
     *
     * @returns {*[]}
     */
    emailSettingsSchema(viewModel) {
        this.email_footer = {
            type:     'quill',
            key:      'email_footer',
            label:    'form.field.email-footer',
            size:     12,
            required: false,
        };

        return [
            [this.email_footer],
        ];
    }

    /**
     * Returns the responsibles form schema
     *
     * @param viewModel
     *
     * @returns {*[]}
     */
    responsiblesSchema(viewModel) {
        this.responsibles = {
            type:         'duallistbox',
            key:          'responsibles',
            label:        'form.field.responsibles-to-contact',
            size:         12,
            required:     false,
            remoteSource: this.managingEntitiesRepository.getUsers.bind(this.managingEntitiesRepository, viewModel.model.id),
        };

        return [
            [this.responsibles],
        ];
    }

    /**
     * Returns the buttons form schema
     *
     * @param viewModel
     *
     * @returns {*[]}
     */
    buttonsSchema(viewModel) {
        this.backButton = {
            type:       'button',
            label:      'form.button.back',
            hidden:     viewModel.appContainer.authenticatedUser.belongsToManagingEntity(false),
            action:     () => viewModel.redirectToRoute('entities.managing-entities.index'),
            attributes: {
                class: 'btn btn-light',
            },
            icon: {
                attributes: {
                    class: 'icon-chevron-left',
                },
            },
        };

        this.submitButton = {
            type:       'submit',
            label:      'form.button.save',
            action:     () => viewModel.submit(),
            attributes: {
                class: 'btn btn-primary',
            },
            icon: {
                attributes: {
                    class: 'icon-floppy-disk',
                },
            },
        };

        this.testButton = {
            type:       'button',
            label:      'form.button.test-billing-settings',
            action:     () => viewModel.testJasminSettings(),
            attributes: {
                class: 'btn btn-success',
            },
            icon: {
                attributes: {
                    class: 'icon-checkmark3',
                },
            },
        };

        this.buttons = {
            type:    'buttons',
            actions: [
                this.backButton,
                this.submitButton,
                this.testButton,
            ],
        };

        return [
            [this.buttons],
        ];
    }

}
