import { inject }                     from 'aurelia-framework';
import { ManagingEntity }             from 'modules/entities/models/managing-entity';
import { ManagingEntitiesRepository } from 'modules/entities/managing-entities/services/repository';

@inject(ManagingEntitiesRepository)
export class FormSchema {

    /**
     * Model default values
     *
     * @type {{}}
     */
    modelDefaults = {};

    /**
     * Constructor
     *
     * @param managingEntitiesRepository
     */
    constructor(managingEntitiesRepository) {
        this.managingEntitiesRepository = managingEntitiesRepository;
    }

    /**
     * Returns a new instance of the model
     *
     * @returns {ManagingEntity}
     */
    model() {
        let model = new ManagingEntity();

        model.assign(this.modelDefaults);

        return model;
    }

    /**
     * Returns the data form schema
     *
     * @param viewModel
     *
     * @returns {*[]}
     */
    schema(viewModel) {
        this.name = {
            type:       'text',
            key:        'name',
            label:      'form.field.designation',
            size:       12,
            required:   false,
            attributes: {
                readonly: true,
            },
        };

        this.district = {
            type:       'text',
            key:        'district',
            label:      'form.field.district',
            size:       4,
            required:   false,
            attributes: {
                readonly: true,
            },
        };

        this.county = {
            type:       'text',
            key:        'county',
            label:      'form.field.county',
            size:       4,
            required:   false,
            attributes: {
                readonly: true,
            },
        };

        this.parish = {
            type:       'text',
            key:        'parish',
            label:      'form.field.parish',
            size:       4,
            required:   false,
            attributes: {
                readonly: true,
            },
        };

        this.address = {
            type:       'text',
            key:        'address',
            label:      'form.field.address',
            size:       12,
            required:   false,
            attributes: {
                readonly: true,
            },
        };

        this.zip_code = {
            type:       'text',
            key:        'zip_code',
            label:      'form.field.zip-code',
            size:       4,
            required:   false,
            attributes: {
                readonly: true,
            },
        };

        this.locality = {
            type:       'text',
            key:        'locality',
            label:      'form.field.locality',
            size:       4,
            required:   false,
            attributes: {
                readonly: true,
            },
        };

        this.phone_number = {
            type:       'text',
            key:        'phone_number',
            label:      'form.field.phone-number',
            size:       4,
            required:   false,
            attributes: {
                readonly: true,
            },
        };

        this.mobile_number = {
            type:       'text',
            key:        'mobile_number',
            label:      'form.field.mobile-number',
            size:       4,
            required:   false,
            attributes: {
                readonly: true,
            },
        };

        this.fax_number = {
            type:       'text',
            key:        'fax_number',
            label:      'form.field.fax-number',
            size:       4,
            required:   false,
            attributes: {
                readonly: true,
            },
        };

        this.email = {
            type:       'email',
            key:        'email',
            label:      'form.field.email',
            size:       4,
            required:   false,
            attributes: {
                readonly: true,
            },
        };

        this.tax_identification_number = {
            type:       'text',
            key:        'tax_identification_number',
            label:      'form.field.tax-number',
            size:       4,
            required:   false,
            attributes: {
                readonly: true,
            },
        };

        this.status = {
            type:       'text',
            key:        'status',
            label:      'form.field.status',
            size:       4,
            required:   false,
            attributes: {
                readonly: true,
            },
        };

        return [
            [this.name],
            [this.district, this.county, this.parish],
            [this.address],
            [this.zip_code, this.locality],
            [this.phone_number, this.mobile_number, this.fax_number],
            [this.email, this.tax_identification_number, this.status],
        ];
    }

    /**
     * Returns the Easypay settings form schema
     *
     * @param viewModel
     *
     * @returns {*[]}
     */
    easypaySettingsSchema(viewModel) {
        this.account_id = {
            type:       'text',
            key:        'account_id',
            label:      'form.field.account-id',
            size:       4,
            required:   false,
            attributes: {
                readonly: true,
            },
        };

        this.api_key = {
            type:       'password',
            key:        'api_key',
            label:      'form.field.api-key',
            size:       4,
            required:   false,
            attributes: {
                readonly: true,
            },
        };

        return [
            [this.account_id, this.api_key],
        ];
    }

    /**
     * Returns the Jasmin settings form schema
     *
     * @param viewModel
     *
     * @returns {*[]}
     */
    jasminSettingsSchema(viewModel) {
        this.client_id = {
            type:       'text',
            key:        'client_id',
            label:      'form.field.client-id',
            size:       3,
            required:   false,
            attributes: {
                readonly: true,
            },
        };

        this.client_secret = {
            type:       'password',
            key:        'client_secret',
            label:      'form.field.client-secret',
            size:       3,
            required:   false,
            attributes: {
                readonly: true,
            },
        };

        this.api_tenant = {
            type:       'text',
            key:        'api_tenant',
            label:      'form.field.tenant-key',
            size:       3,
            required:   false,
            attributes: {
                readonly: true,
            },
        };

        this.api_organization = {
            type:       'text',
            key:        'api_organization',
            label:      'form.field.organization-key',
            size:       3,
            required:   false,
            attributes: {
                readonly: true,
            },
        };

        this.periodic_inspection_item_code = {
            type:       'text',
            key:        'periodic_inspection_item_code',
            label:      'form.field.periodic-inspection-item-code',
            size:       3,
            required:   false,
            attributes: {
                readonly: true,
            },
        };

        this.extraordinary_inspection_item_code = {
            type:       'text',
            key:        'extraordinary_inspection_item_code',
            label:      'form.field.extraordinary-inspection-item-code',
            size:       3,
            required:   false,
            attributes: {
                readonly: true,
            },
        };

        this.reinspection_item_code = {
            type:       'text',
            key:        'reinspection_item_code',
            label:      'form.field.reinspection-item-code',
            size:       3,
            required:   false,
            attributes: {
                readonly: true,
            },
        };

        this.sealing_item_code = {
            type:       'text',
            key:        'sealing_item_code',
            label:      'form.field.sealing-item-code',
            size:       3,
            required:   false,
            attributes: {
                readonly: true,
            },
        };

        this.financial_account_code = {
            type:       'text',
            key:        'financial_account_code',
            label:      'form.field.financial-account-code',
            size:       3,
            required:   false,
            attributes: {
                readonly: true,
            },
        };

        return [
            [this.client_id, this.client_secret, this.api_tenant, this.api_organization],
            [this.periodic_inspection_item_code, this.extraordinary_inspection_item_code, this.reinspection_item_code, this.sealing_item_code],
            [this.financial_account_code],
        ];
    }

    /**
     * Returns the SMS settings form schema
     *
     * @param viewModel
     *
     * @returns {*[]}
     */
    smsSettingsSchema(viewModel) {
        this.msisdn = {
            type:       'text',
            key:        'msisdn',
            label:      'form.field.msisdn',
            size:       4,
            required:   false,
            attributes: {
                readonly: true,
            },
        };

        this.password = {
            type:       'password',
            key:        'password',
            label:      'form.field.password',
            size:       4,
            required:   false,
            attributes: {
                readonly: true,
            },
        };

        return [
            [this.msisdn, this.password],
        ];
    }

    /**
     * Returns the email settings form schema
     *
     * @param viewModel
     *
     * @returns {*[]}
     */
    emailSettingsSchema(viewModel) {
        this.email_footer = {
            type:       'quill',
            key:        'email_footer',
            label:      'form.field.email-footer',
            size:       12,
            required:   false,
            attributes: {
                readonly: true,
            },
        };

        return [
            [this.email_footer],
        ];
    }

    /**
     * Returns the responsibles form schema
     *
     * @param viewModel
     *
     * @returns {*[]}
     */
    responsiblesSchema(viewModel) {
        this.responsibles = {
            type:         'duallistbox',
            key:          'responsibles',
            label:        'form.field.responsibles-to-contact',
            size:         12,
            remoteSource: this.managingEntitiesRepository.getUsers.bind(this.managingEntitiesRepository, viewModel.model.id),
            required:     false,
            attributes:   {
                disabled: true,
            },
        };

        return [
            [this.responsibles],
        ];
    }

    /**
     * Returns the buttons form schema
     *
     * @param viewModel
     *
     * @returns {*[]}
     */
    buttonsSchema(viewModel) {
        this.backButton = {
            type:       'button',
            label:      'form.button.back',
            hidden:     viewModel.appContainer.authenticatedUser.belongsToManagingEntity(false),
            action:     () => viewModel.redirectToRoute('entities.managing-entities.index'),
            attributes: {
                class: 'btn btn-light',
            },
            icon: {
                attributes: {
                    class: 'icon-chevron-left',
                },
            },
        };

        this.buttons = {
            type:    'buttons',
            actions: [
                this.backButton,
            ],
        };

        return [
            [this.buttons],
        ];
    }

}
