import { bindable, inject }              from 'aurelia-framework';
import { AppContainer }                  from 'resources/services/app-container';
import { BaseFormViewModel }             from 'base-form-view-model';
import { FormSchema }                    from 'modules/entities/managing-entities/view/form-schema';
import { ManagingEntitiesRepository }    from 'modules/entities/managing-entities/services/repository';
import { ManagingEntityFilesRepository } from 'modules/entities/managing-entities/services/files-repository';

@inject(AppContainer, FormSchema, ManagingEntitiesRepository, ManagingEntityFilesRepository)
export class ViewManagingEntity extends BaseFormViewModel {

    @bindable headerTitle     = 'form.title.view-record';
    @bindable editRecordRoute = 'entities.managing-entities.edit';
    @bindable formId          = 'entities-managing-entities-view-form';

    alert  = {};
    model  = {};
    schema = {};

    viewing = true;

    /**
     * Constructor
     *
     * @param appContainer
     * @param formSchema
     * @param repository
     */
    constructor(appContainer, formSchema, repository, filesRepository) {
        super(appContainer);

        this.formSchema      = formSchema;
        this.repository      = repository;
        this.filesRepository = filesRepository;
        this.editRecordRoute = appContainer.authenticatedUser.can(['entities.managing-entities.manage', 'entities.managing-entities.edit']) ? this.editRecordRoute : null;
    }

    /**
     * Handles canActivate event
     */
    canActivate(params) {
        const authenticatedUser = this.appContainer.authenticatedUser;
        const belongsToEntity   = authenticatedUser.can('entities.managing-entities.view') && authenticatedUser.belongsToEntity(params.id);

        return super.authorize(authenticatedUser.can('entities.managing-entities.manage') || belongsToEntity);
    }

    /**
     * Handles activate event
     */
    activate(params, navigationInstruction) {
        super.activate(navigationInstruction);

        this.initialModel = this.formSchema.model();
        this.model        = this.formSchema.model();

        return this.fetchData(params).then(() => {
            this.schema                = this.formSchema.schema(this);
            this.easypaySettingsSchema = this.formSchema.easypaySettingsSchema(this);
            this.jasminSettingsSchema  = this.formSchema.jasminSettingsSchema(this);
            this.smsSettingsSchema     = this.formSchema.smsSettingsSchema(this);
            this.emailSettingsSchema   = this.formSchema.emailSettingsSchema(this);
            this.responsiblesSchema    = this.formSchema.responsiblesSchema(this);
            this.buttonsSchema         = this.formSchema.buttonsSchema(this);
        });
    }

    /**
     * Fetches data from server
     *
     * @param {id}
     *
     * @returns {Promise.<*>|Promise}
     */
    fetchData({ id }) {
        return this.repository.find(id).then((response) => {
            this.initialModel.assign(response);
            this.model.assign(response);
        });
    }

}
