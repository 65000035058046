import { Entity } from 'modules/entities/models/entity';

export class ManagingEntity extends Entity {

    msisdn                             = null;
    password                           = null;
    account_id                         = null;
    api_key                            = null;
    client_id                          = null;
    client_secret                      = null;
    api_tenant                         = null;
    api_organization                   = null;
    periodic_inspection_item_code      = null;
    extraordinary_inspection_item_code = null;
    reinspection_item_code             = null;
    sealing_item_code                  = null;
    financial_account_code             = null;
    email_footer                       = null;

}
