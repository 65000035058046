import { BaseModel } from 'modules/core/models/base-model';

export class Technician extends BaseModel {

    entity_id                   = null;
    associate_user_id           = null;
    user_id                     = null;
    code                        = null;
    name                        = null;
    address                     = null;
    district_id                 = null;
    county_id                   = null;
    parish_id                   = null;
    zip_code                    = null;
    locality                    = null;
    phone_number                = null;
    mobile_number               = null;
    email                       = null;
    civil_identification_number = null;
    tax_identification_number   = null;
    status_id                   = null;

}
