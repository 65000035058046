import {BaseModel} from 'modules/core/models/base-model';

export class Entity extends BaseModel {

    name                      = null;
    acronym                   = null;
    address                   = null;
    district_id               = null;
    county_id                 = null;
    parish_id                 = null;
    zip_code                  = null;
    locality                  = null;
    phone_number              = null;
    mobile_number             = null;
    fax_number                = null;
    email                     = null;
    tax_identification_number = null;
    status_id                 = null;
    responsibles              = [];

}
