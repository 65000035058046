import { Entity } from 'modules/entities/models/entity';

export class ControllingEntity extends Entity {

    periodic_inspection_fee      = null;
    extraordinary_inspection_fee = null;
    reinspection_fee             = null;
    sealing_fee                  = null;

}
